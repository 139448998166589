import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from "framer-motion";
import AnimatedText from "../AnimatedText";
import Accordion from "../Accordion";
import designThinking from './designThinking.json';
import designProcess from './designProcess.json'
import './style.scss';

const DesignThinking = () => {
    return (
        <>
            <Container fluid className="HomeBanner">
                <div className="content">
                    <Row className="align-center">
                        <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                            <h2 className="xl white"><span style={{ fontSize: 20 }} className="grey">Unlocking Innovation:</span><br /><AnimatedText line1="Our Design" line2="Thinking Approach" /></h2>
                        </Col>
                        <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                            scale: 1, transition: {
                                delay: 0.5, duration: 1
                            },
                        }} style={{ maxWidth: '100%' }} className="aligh-btm" src="images/DesignThinkingImage.png" alt="" /></Col>
                    </Row>
                </div>
                <img className="HomeBannerBg mob-rt-40-per" src="images/DesignThinkingBg.png" alt="" />
            </Container>
            <div className="section-pad">
                <Container fluid>
                    <motion.h4 initial={{ borderWidth: 0 }}
                        whileInView={{
                            borderWidth: 5, transition: {
                                delay: 0.5, duration: 1
                            }
                        }}>DESIGN<br /> THINKING</motion.h4>
                    <p className="main-header-text">In our digital agency, design thinking isn't just a process; it's a philosophy that inspires innovation. It drives our creative journey, focusing on the user at every step. From idea to execution, we use design thinking to solve complex challenges and craft impactful digital experiences. With curiosity and collaboration, we reimagine possibilities and shape the future of digital excellence. Welcome to a space where creativity thrives and design thinking leads us to new innovations.</p>
                    <br />
                    <br />
                    <br />
                </Container>
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12} lg={6}>
                            <Row>
                                <Col sm={12} md={12} lg={{span: 11, offset: 0}} >
                                    <div className="sector-head">Essential Pillars of Exceptional User Experiences
                                        <br />
                                        <div className="subText">The Fundamentals of UX - Honeycomb, developed by Peter Morville, encapsulates seven key attributes essential for creating effective user experiences. Each facet of the honeycomb represents a fundamental principle that contributes to the overall success of a digital product or service.</div>
                                    </div>
                                    <Accordion content={designThinking} subText="By incorporating these seven principles of the Honeycomb framework into the design and development process, designers and developers can create user experiences that are valuable, useful, desirable, accessible, credible, findable, and usable, ultimately leading to greater user satisfaction and engagement." />
                                    <br />
                                    <br />
                                    <br />
                                </Col></Row></Col>
                        <Col sm={12} md={12} lg={6} style={{ textAlign: 'center' }}>
                            <h3 id="accordion-right-side-header" className="accordion-right-side-header align-ctr">The Fundamentals of UX - Honeycomb</h3>
                            <motion.img initial={{ scale: 0.8 }} whileInView={{
                                scale: 1, transition: {
                                    delay: 0.5, duration: 1
                                },
                            }} style={{ maxWidth: '100%', width: '80%', textAlign: 'center' }} src="images/Honeycomb.png" alt="" />
                            <br />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={6} style={{ textAlign: 'center' }}>

                            <h3 id="accordion-right-side-header" className="accordion-right-side-header align-ctr">OUR DESIGN PROCESS</h3>
                            <motion.img initial={{ scale: 0.8 }} whileInView={{
                                scale: 1, transition: {
                                    delay: 0.5, duration: 1
                                },
                            }} style={{ maxWidth: '100%', width: '80%', textAlign: 'center' }} src="images/Process.png" alt="" />
                            <br />
                            <br />
                            <br />
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <Row>
                                <Col sm={12} md={12} lg={{span: 11, offset: 1}}>
                                    <div className="sector-head">Elevating Digital Experiences Through Innovative Design Thinking
                                        <br />
                                        <div className="subText">At Digitellect, our human-centric design thinking philosophy prioritizes empathy, creativity, and iterative innovation. We understand users' needs, desires, and challenges to create resonant solutions.</div>
                                    </div>
                                    <Accordion content={designProcess} />
                                </Col></Row></Col>

                    </Row>
                </Container></div>
        </>

    );
}

export default DesignThinking