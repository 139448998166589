import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { ArrowDownRight, ArrowUpRight } from 'react-bootstrap-icons';
import { motion } from "framer-motion";
import AnimatedText from "../AnimatedText";
import Button from "../Button";
import './style.scss';

const defaultFixedHeight = {
    height: 65,
    overflow: 'hidden'
}
const defaultApproachHeight = {
    height: 105,
    overflow: 'hidden'
}

const AboutUs = ({ isHome = false }: any) => {
    const [moreAboutHome, setMoreAboutHome] = useState(false)
    const [moreVision, setMoreVision] = useState(false)
    const [moreValues, setMoreValues] = useState(false)
    const [moreProcess, setMoreProcess] = useState(false)
    const [moreTeam, setMoreTeam] = useState(false)

    const InnerContent = () => <Container fluid><Row className="more-btn link-text">
        <Col sm={12} md={6} lg={3}><Link to="/about#ourStory" ><Button variant="primary" type="button">
            Our Story: <ArrowDownRight size={20} className="right-align-icon" />
        </Button></Link></Col>
        <Col sm={12} md={6} lg={3}><Link to="/about#ourVision" ><Button variant="primary" type="button">
            Our Vision: <ArrowDownRight size={20} className="right-align-icon" />
        </Button></Link></Col>
        <Col sm={12} md={6} lg={3}><Link to="/about#ourApproach" ><Button variant="primary" type="button">
            Our Approach: <ArrowDownRight size={20} className="right-align-icon" />
        </Button></Link></Col>
        <Col sm={12} md={6} lg={3}>
            <Link to="/about#whyUs" > <Button variant="primary" type="button">Why Choose US: <ArrowDownRight size={20} className="right-align-icon" /></Button></Link>
        </Col>
    </Row></Container>
    return <div className="about-us">

        {isHome ? <Container fluid><div>
            <br className="hideOnMobile"/>
            <br className="hideOnMobile" />
            <br className="hideOnMobile"/>
            <p className="main-content-text">Welcome to Digitellect, we're the bridge between creativity and digital success. <span>With our team of experts, we craft visually stunning digital solutions that align  with your brand identity, ensuring your online presence stands out. Whether it's design, development, or digital marketing, we're here to bring your vision to life with excellence and collaboration.</span></p>
        </div>
            <Row>
                <Col>
                    <div className="link-text text-end mb-20" onClick={() => setMoreAboutHome(!moreAboutHome)}>
                        {moreAboutHome ? <>LESS </> : <>MORE </>} ABOUT US
                    </div>
                </Col>
            </Row>
        </Container> :
            <>
                <Container fluid className="HomeBanner">
                    <div className="content">
                        <Row className="align-center">
                            <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                                <h2 className="xl white"><span style={{ fontSize: 20 }} className="grey">Fulfilling Visions:</span><br /><AnimatedText line1="Crafting Digital Dreams" /></h2>
                            </Col>
                            <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                                scale: 1, transition: {
                                    delay: 0.5, duration: 1
                                },
                            }} src="images/AboutUsImage.png" alt="" /></Col>
                        </Row>
                    </div>
                    <img className="HomeBannerBg mob-rt-40-per" src="images/AboutUsBg.png" alt="" />
                </Container>
                <div className="section-pad">
                    <Container fluid>
                        <motion.h4 initial={{ borderWidth: 0 }}
                            whileInView={{
                                borderWidth: 5, transition: {
                                    delay: 0.5, duration: 1
                                }
                            }} id="aboutUs">ABOUT<br></br> US</motion.h4>
                        <p className="main-header-text">Welcome to Digitellect, where we transform ideas into digital masterpieces. As a leading digital design agency, we specialize in crafting innovative solutions that captivate audiences and elevate brands in the digital landscape.</p>
                    </Container></div>
            </>}

        {(moreAboutHome || !isHome) && (isHome ? <InnerContent /> : <div className="section-pad"><InnerContent /></div>)}
        {
            !isHome && <div className="section-pad"><Container fluid>

                <Row id="ourStory">
                    <Col sm={12} md={12} lg={4}>
                        <div className="our-vision">
                            <p>Our Story:</p>
                            <h5>ELEVATING DIGITAL INNOVATION THROUGH DECADES OF EXPERIENCE</h5>
                            <p style={!moreVision ? defaultFixedHeight : {}}>Our story at Digitellect started with a vision to revolutionize creativity in the digital realm. With over two decades of collective experience, our founders have cultivated a culture of design excellence. Fueled by our passion for innovation, we continue to deliver exceptional digital experiences that redefine industry standards.</p>
                            <div className="link-text text-end read-more" onClick={() => setMoreVision(!moreVision)}>
                                READ {moreVision ? <>LESS <ArrowUpRight size={14} className="right-align-icon svg-right-align t6" /></> : <>MORE <ArrowDownRight size={14} className="right-align-icon svg-right-align t6" /></>}
                            </div>
                        </div>
                    </Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Vision-1.png" alt="" /></Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Vision-2.png" alt="" /></Col>
                </Row>
                <br />

                <Row id="ourVision">
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Values.png" alt="" /></Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Values2.png" alt="" /></Col>
                    <Col sm={{ span: 12, order: 'first' }} md={{ span: 12, order: 'first' }} xs={{ span: 12, order: 'first' }} lg={4}>
                        <div className="our-values">
                            <p>Our Vision:</p>
                            <h5>PIONEERING TRANSFORMATIVE DIGITAL EXPERIENCES</h5>
                            <p style={!moreValues ? defaultFixedHeight : {}}>Our agency envisions leading the way in crafting transformative digital experiences that seamlessly blend aesthetics with functionality. With innovation and user-centric solutions, we aim to set new industry standards, empowering businesses and individuals alike. Our vision is to inspire, engage, and foster connectivity in the ever-evolving digital landscape.</p>
                            <div className="link-text text-end read-more" onClick={() => setMoreValues(!moreValues)}>
                                READ {moreValues ? <>LESS <ArrowUpRight size={14} className="right-align-icon svg-right-align t6" /></> : <>MORE <ArrowDownRight size={14} className="right-align-icon svg-right-align t6" /></>}
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />

                <Row id="ourApproach">
                    <Col sm={12} md={12} lg={4}>
                        <div className="our-process">
                            <div>
                                <p>Our Approach:</p>
                                <h5>CRAFTING SOLUTIONS, TOGETHER</h5>
                                <div style={!moreProcess ? defaultApproachHeight : {}}>
                                    <p>At Digitellect, we believe in the power of collaboration and creativity. Our team of designers, developers, and strategists work hand in hand to bring your vision to life. By combining cutting-edge technology with artistic flair, we create solutions that leave a lasting impression.</p>
                                </div></div>
                            <div className="link-text text-end read-more" onClick={() => setMoreProcess(!moreProcess)}>
                                READ {moreProcess ? <>LESS <ArrowUpRight size={14} className="right-align-icon svg-right-align svg-right-align t6" /></> : <>MORE <ArrowDownRight size={14} className="right-align-icon svg-right-align t6" /></>}
                            </div>
                        </div>
                    </Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Approuch1.jpg" alt="" /></Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Our-Approuch2.jpg" alt="" /></Col>
                </Row>
                <br />

                <Row id="whyUs">
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Why-Choose-Us.jpg" alt="" /></Col>
                    <Col className="pad-m-10" sm={12} md={12} lg={4}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/Why-Choose-Us2.jpg" alt="" /></Col>
                    <Col sm={{ span: 12, order: 'first' }} md={{ span: 12, order: 'first' }} xs={{ span: 12, order: 'first' }} lg={4}>
                        <div className="our-team">
                            <p>Why Choose Us:</p>
                            <h5>EXCEEDING EXPECTATIONS, EVERY TIME</h5>
                            <div style={!moreTeam ? defaultFixedHeight : {}}>
                                <p>What sets us apart is our unwavering commitment to excellence and client satisfaction. We prioritize your goals and aspirations, ensuring that every project we undertake exceeds expectations. With a focus on innovation and quality, we are dedicated to helping you succeed in the digital world.</p>
                            </div>
                            <div className="link-text text-end read-more" onClick={() => setMoreTeam(!moreTeam)}>
                                READ {moreTeam ? <>LESS <ArrowUpRight size={14} className="right-align-icon svg-right-align t6" /></> : <>MORE <ArrowDownRight size={14} className="right-align-icon svg-right-align t6" /></>}
                            </div>
                        </div>
                    </Col>
                </Row> <br /></Container></div>
        }
    </div>
}

export default AboutUs