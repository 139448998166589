import React from "react"
import { motion } from "framer-motion";
import './style.scss';

const RefundCancellation = () => {

    return <>
        <p>Thank you for choosing Digitellect for your design and development needs.</p>
        <p>At Digitellect, we strive to provide the highest quality of service to our clients. However, we understand that there may be situations where you need to cancel or request a refund. Please review our refund and cancellation policy carefully before making any payments.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Design & Web Development</motion.h5>
        <p>Design and web development projects require significant resources, time, and expenses. Once a payment or deposit is made for a project, it is non-refundable. If a project is canceled, delayed, or postponed by the client, all amounts paid will be retained by Digitellect. In such cases, an additional fee may be charged for work completed beyond the initial payment.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Logo Design, Branding, Web Design, UI-UX Design, SEO, SMM, & Internet Marketing</motion.h5>
        <p>Payments for any of our design, development, or marketing services are non-refundable, regardless of whether a fee was paid in advance. Once a payment or deposit is made, there are no exceptions to our refund policy.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Setup Fees</motion.h5>
        <p>Setup fees are applied to cover expenses incurred during the setup phase of any project and are non-refundable.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Chargebacks and Payment Disputes</motion.h5>
        <p>If a client initiates a chargeback or payment dispute with their bank or credit card company, all services and projects will be suspended. Digitellect reserves the right to undo any work completed and charge the client for any associated fees. Outstanding balances resulting from chargebacks must be paid in full before services are restored.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Website Hosting</motion.h5>
        <p>All hosting payments, whether for current or past months, are non-refundable. Web hosting accounts are set up upon order and continue until canceled in writing by the client. Hosting fees are due even if the account is not actively used.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Account Suspension</motion.h5>
        <p>Hosting payments that are 15 days past due will result in account suspension. Refunds for prepaid hosting services will only be issued for the remaining months not yet elapsed at the time of cancellation.</p>
        <p>Digitellect reserves the right to disable or terminate user accounts or services for violations of our terms and policies, with no refunds issued.</p>
        <p>For any billing issues or concerns, please contact us, and we will be happy to assist you.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Please also review our Terms of Use for additional information.</motion.h5>
        <p>By engaging Digitellect for services, you agree to abide by this refund and cancellation policy.</p>
        <p>Thank you for your understanding and cooperation.</p>

    </>
}

export default RefundCancellation