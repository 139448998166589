import React from "react"
import { Card, Row, Col } from 'react-bootstrap';
import { motion } from "framer-motion";
import {
    Link
} from "react-router-dom";
import { ArrowUpRight } from 'react-bootstrap-icons';
import './Service.scss';
const Box = ({ title, text, desc, icon }: any) => {
    return <motion.div initial={{ scale: 0.75 }} whileInView={{
        scale: 1, transition: {
            delay: 0.5, duration: 1
        },
    }}><Card>
            <Card.Body>
                <Row>
                    <Col xs={3} className="hidden-xs">
                        <img className="default" src={`images/${icon}.png`} alt="" />
                        <img className="hover" src={`images/${icon}_Hover.png`} alt="" />
                    </Col>
                    <Col xs={12} md={9} lg={9} className="bor-left">
                        <Link to={`/services/${title.split(' ').join('_')}#serviceDetail`}><div className="text-more">More <ArrowUpRight size={14} className="right-align-icon" /></div></Link>
                        <Card.Title>{title.toUpperCase()}</Card.Title>
                        <Card.Text className="default">{text}</Card.Text>
                        <Card.Subtitle className="hover">
                            {desc}
                        </Card.Subtitle>
                    </Col>
                </Row>
            </Card.Body>
        </Card></motion.div>
}
export default Box