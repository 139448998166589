import React from "react";
import { motion } from "framer-motion";

const sentence = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.5, duration: 1,
            staggerChildren: 0.08
        }
    }
}

const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0
    }
}

const AnimatedText = ({ line1, line2 = '' }: any) => <motion.span variants={sentence} initial="hidden" animate="visible">
    {line1.split('').map((char: any, index: any) => <motion.span key={char + '-' + index} variants={letter}>{char}</motion.span>)}
    {line2 && <><br /> {line2.split('').map((char: any, index: any) => <motion.span key={char + '-' + index} variants={letter}>{char}</motion.span>)}</>}
</motion.span>

export default AnimatedText