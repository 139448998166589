import React, { useEffect, useState } from "react"
import { Container, Form, Row, Col } from 'react-bootstrap';

const Where = ({ register, errors, show, watch, Country, State, City, getValues }: any) => {
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const watchCountry = watch("country")
    const watchState = watch("state")
    useEffect(() => {
        const _countries: any = Country.getAllCountries()
        setCountries(_countries)
        setStates([])
        setCities([])
    }, [Country])

    useEffect(() => {
        if (watchCountry) {
            const _states: any = State.getStatesOfCountry(watchCountry)
            console.log(_states)
            setStates(_states)
            setCities([])
        }
    }, [State, watchCountry])
    useEffect(() => {
        if (watchCountry && watchState) {
            const _cities: any = City.getCitiesOfState(watchCountry, watchState)
            setCities(_cities)
        }
    }, [City, watchCountry, watchState])
    return show && <Container fluid style={{ paddingTop: 50, display: show ? 'block' : 'none' }}>
        <div className="box-wrap">
            {/* {(loadingCountries || loadingStates || loadingCities) && <><div style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                background: "black",
                opacity: 0.5
            }}></div><div style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
            }}>
                    <Spinner animation="grow" />
                </div></>} */}
            <Row className="mb-5">
                <Col sm={12} md={12} lg={12}>
                    <Form.Select id="country"
                        placeholder="Select Country*"
                        aria-describedby="Country" aria-invalid={errors.country ? "true" : "false"} {...register("country", {
                            required: "Please select country"
                        })}>
                        <option value="">Select Country</option>
                        {countries.map((country: any, index: any) => <option key={index} value={country.isoCode}>{country.name}</option>)}
                    </Form.Select>
                    {errors.country && <p role="alert">{`${errors.country.message}`}</p>}
                </Col>
                <Col sm={12} md={12} lg={12}>
                    {getValues('country') && !states.length ?
                        <Form.Control
                            type="text"
                            id="state"
                            placeholder="State*"
                            aria-describedby="State"
                            {...register("state", {
                                required: "Please enter state"
                            })}
                            aria-invalid={errors.name ? "true" : "false"}
                        /> : <Form.Select id="state"
                            placeholder="Select State*"
                            aria-describedby="State" aria-invalid={errors.state ? "true" : "false"} {...register("state", {
                                required: "Please select state"
                            })}>
                            <option value="">Select State</option>
                            {states.map((state: any, index: any) => <option key={index} value={state.isoCode}>{state.name}</option>)}
                        </Form.Select>}
                    {errors.state && <p role="alert">{`${errors.state.message}`}</p>}
                </Col>
                <Col sm={12} md={12} lg={12}>
                    {getValues('country') && (!states.length || getValues('state')) && !cities.length ? <Form.Control
                        type="text"
                        id="city"
                        placeholder="City*"
                        aria-describedby="City"
                        {...register("city", {
                            required: "Please enter city"
                        })}
                        aria-invalid={errors.name ? "true" : "false"}
                    /> : <Form.Select id="city"
                        placeholder="Select City*"
                        aria-describedby="City" aria-invalid={errors.city ? "true" : "false"} {...register("city", {
                            required: "Please select city"
                        })}>
                        <option value="">Select City</option>
                        {cities.map((city: any, index: any) => <option key={index} value={city.name}>{city.name}</option>)}
                    </Form.Select>}
                    {errors.city && <p role="alert">{`${errors.city.message}`}</p>}
                </Col>
            </Row>
        </div>
    </Container>
}

export default Where