import React from "react"
import { motion } from "framer-motion";
import './style.scss';

const Terms = () => {

    return <>
        <p>Welcome to Digitellect! These Terms of Use govern your access to and use of our website and services. By accessing or using our website or services, you agree to comply with these terms. If you do not agree with any part of these terms, please do not use our website or services.
            Use of Our Website and Services</p>
        <p>You may use our website and services for lawful purposes and in accordance with these terms. You agree not to:</p>
        <ul>
            <li>Violate any applicable laws, regulations, or third-party rights.</li>
            <li>Use our website or services in any manner that could damage, disable, overburden, or impair our servers or networks.</li>
            <li>Attempt to gain unauthorized access to any part of our website, services, or user accounts.</li>
            <li>Transmit any viruses, worms, or other malicious code through our website or services.</li>
        </ul>

        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Intellectual Property Rights</motion.h5>
        <p>All content on our website and services, including but not limited to text, graphics, logos, images, and software, is the property of Digitellect or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, modify, distribute, or display any content without our prior written consent.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>User Content</motion.h5>
        <p>You may have the opportunity to submit content to our website or services, such as comments, reviews, or feedback. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such content worldwide in any media.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Third-Party Links</motion.h5>
        <p>Our website and services may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You access third-party websites or services at your own risk and should review their terms and policies.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Limitation of Liability</motion.h5>
        <p>To the fullest extent permitted by law, Digitellect and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our website or services.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Changes to These Terms</motion.h5>
        <p>We may update these Terms of Use from time to time by posting the updated terms on our website. Your continued use of our website or services after any changes indicates your acceptance of the updated terms. Please check this page regularly for updates.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Governing Law</motion.h5>
        <p>These Terms of Use shall be governed by and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions.</p>
        <motion.h5 initial={{ borderWidth: 0 }}
            whileInView={{
                borderWidth: 5, transition: {
                    delay: 0.5, duration: 1
                }
            }}>Contact Us</motion.h5>
        <p>If you have any questions or concerns about these Terms of Use, please contact us at <a href="mailto:privacy@digitellect.com">privacy@digitellect.com</a>.</p>
        <p>These Terms of Use were last updated on May 14, 2024.</p>

    </>
}

export default Terms