import React, { useState } from "react"
import { Container, Form, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form"
import Button from "../Button";
import { newsletter } from "../api";

const Newsletter = () => {
    const [response, setResponse] = useState({ status: '' })
    const { register, formState: { errors }, handleSubmit, reset } = useForm()
    const onSubmit = (data: any) => {
        newsletter(data.email).then((res) => {
            setResponse(res)
            reset()
        }).catch(() => {
            setResponse({ status: 'error' })
        })
    }
    return <Container fluid>

        <Form onSubmit={handleSubmit(onSubmit)}><div className="row newsletter-panel d-flex align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <h2>NEWSLETTER</h2>
                <p>GET THE INSIDE SCOOP ON INDUSTRY TRENDS</p>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 ">
                <div className="row">
                    <div className="col-lg-9 col-md-6 col-sm-12">
                        <Form.Control
                            type="text"
                            id="email"
                            placeholder="YOUR EMAIL"
                            aria-describedby="EMAIL"
                            {...register("email", {
                                required: "Please enter email address", validate: {
                                    matchPattern: (v) =>
                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                        "Please enter valid email address",
                                }
                            })}
                            aria-invalid={errors.email ? "true" : "false"}
                        />
                        {errors.email && <p role="alert">{`${errors.email.message}`}</p>}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <Button variant="primary" type="submit">
                            SUBSCRIBE
                        </Button>
                    </div>
                </div>
            </div>

        </div></Form>

        <Modal show={Boolean(response.status)} onHide={() => setResponse({ status: '' })}>
            <Modal.Header closeButton>
                <Modal.Title>{response.status === 'success' ? 'Success' : 'Error'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{response.status === 'success' ? <p>You have successfully subscribed to our newletter</p> : <p>We are currently experiencing a technical glitch. We apologize for any inconvenience.<br /> Please try again later</p>}</Modal.Body>
            <Modal.Footer>
                <Button variant={response.status === 'success' ? 'primary' : 'danger'} onClick={() => setResponse({ status: '' })}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    </Container>
}

export default Newsletter