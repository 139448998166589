import React from "react"
import { Container, Form, Row, Col } from 'react-bootstrap';

const Where = ({ register, errors, services, show }: any) => {
    return show && <Container fluid style={{ paddingTop: 50, display: show ? 'block' : 'none' }}>
        <div className="box-wrap">
            <Row className="mb-5">
                <Col sm={12} md={12} lg={12}>
                    <Form.Select id="service"
                        placeholder="Select Service*"
                        aria-describedby="Service" aria-invalid={errors.service ? "true" : "false"} {...register("service", {
                            required: "Please select service"
                        })}>
                        <option value="">Select Service</option>
                        {services.map((service: any, index: any) => <option key={index} value={service}>{service}</option>)}
                    </Form.Select>
                    {errors.service && <p role="alert">{`${errors.service.message}`}</p>}
                </Col>
            </Row>
        </div>
    </Container>
}

export default Where