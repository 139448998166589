import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { PlusLg, DashLg } from 'react-bootstrap-icons';
import { motion, AnimatePresence } from "framer-motion";
import Button from "../Button";
import AnimatedText from "../AnimatedText";
import Services from '../Services/services.json';
import './style.scss';


const Portfolio = ({ isHome = false }: any) => {
    const location = useLocation()
    const { service } = useParams();
    const { pathname } = location
    const items: any = Services;
    const [activeService, setActiveService] = useState(Object.keys(items)[0])
    const [onlyBrief, setOnlyBrief] = useState(true)
    const [viewImage, setViewImage] = useState("")
    useEffect(() => {
        setOnlyBrief(!pathname.includes('/portfolio'))
    }, [pathname])
    useEffect(() => {
        if (service) {
            setActiveService(service.split('_').join(' '))
        }
    }, [service])

    const InnerContent = () => <Container fluid>
        <div className="our-portfolio">
            <motion.h4 initial={{ borderWidth: 0 }}
                whileInView={{
                    borderWidth: 5, transition: {
                        delay: 0.5, duration: 1
                    }
                }}>OUR<br></br> PORTFOLIO</motion.h4>
            <p className="main-header-text">In our portfolio, each project is a visual narrative—a testament to the fusion of creativity and functionality. Explore a kaleidoscope of designs that not only captivate but redefine the essence of digital excellence.</p>
        </div>

        <Row>
            <Col sm={12} md={12} lg={4}>
                <Row>
                    <Col sm={12} md={12} lg={11}>
                        <h6 className="mb-20"> SECTORS</h6>
                        {Object.keys(items).map((item, index) => <Fragment key={index}><div id={item.toLowerCase().split(' ').join('-')} onClick={() => {
                            setActiveService(item)
                        }} className={activeService === item ? 'sector-head' : 'other-services'}>{item.toUpperCase()}
                            {activeService === item ? <DashLg size={20} className="icon-align-right" /> : <PlusLg size={20} className="icon-align-right" />}
                        </div>

                            <div className="design-services" style={{ marginBottom: 7 }}>
                                <AnimatePresence initial={false}>
                                    {activeService === item &&
                                        <motion.section
                                            key="content"
                                            initial="open"
                                            animate="open"
                                            exit="collapsed"
                                            variants={{
                                                open: { height: "auto" },
                                                collapsed: { height: 0 }
                                            }}
                                            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                                        ><p>{items[activeService]['altDescription']}</p></motion.section>}</AnimatePresence>
                            </div>

                            {activeService === item && <Col className="mb-10 mobilePortfolioDetail" sm={12} md={12} lg={8}>
                                <h6 id="latestWorks" className="mb-20 pt-m20">LATEST WORKS</h6>
                                <Masonry
                                    breakpointCols={3}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column">
                                    {(onlyBrief ? items[activeService]['portfolio'].slice(0, 4) : items[activeService]['portfolio']).map((item: any, index: any) => <div key={index}>
                                        <div><motion.img initial={{ scale: 0.8 }} whileInView={{
                                            scale: 1, transition: {
                                                delay: 0.5, duration: 1
                                            },
                                        }} src={item} alt="" className="work-items" /></div>
                                        <div className="text-end">
                                            <Button onClick={() => setViewImage(item)} className="btn-viewmore mt-mb" variant="primary" type="button">
                                                View Design
                                            </Button>
                                        </div>
                                    </div>)}
                                </Masonry>
                                {/* <Row>
            
        </Row> */}
                            </Col>}

                        </Fragment>)}
                        <div className="brand-box">Elevating Your Online Presence Through Compelling Visuals
                            <br />
                            <div className="d-grid gap-2 mt-20">
                                <Button variant="primary" type="button">
                                    <a className="call-button" href="tel:+919980838226">LET'S TALK</a>
                                </Button>
                            </div></div>
                    </Col>
                </Row>

            </Col >

            <Col className="mb-10 defaultPortfolioDetail" sm={12} md={12} lg={8}>
                <h6 id="latestWorks" className="mb-20 pt-m20">LATEST WORKS</h6>
                <Masonry
                    breakpointCols={3}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {(onlyBrief ? items[activeService]['portfolio'].slice(0, 4) : items[activeService]['portfolio']).map((item: any, index: any) => <div key={index}>
                        <div><motion.img initial={{ scale: 0.8 }} whileInView={{
                            scale: 1, transition: {
                                delay: 0.5, duration: 1
                            },
                        }} src={item} alt="" className="work-items" /></div>
                        <div className="text-end">
                            <Button onClick={() => setViewImage(item)} className="btn-viewmore mt-mb" variant="primary" type="button">
                                View Design
                            </Button>
                        </div>
                    </div>)}
                </Masonry>
                {/* <Row>
            
        </Row> */}
            </Col>

        </Row>
        <Modal size="lg" show={Boolean(viewImage)} onHide={() => setViewImage("")}>
            <Modal.Header closeButton>
                <Modal.Title>{activeService}</Modal.Title>
            </Modal.Header>
            <Modal.Body><motion.img initial={{ scale: 0.8 }} whileInView={{
                scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
            }} alt="" src={viewImage} /></Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={() => setViewImage("")}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>


    </Container>
    return (<>
        {!isHome && <Container fluid className="HomeBanner">
            <div className="content">
                <Row className="align-center">
                    <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                        <h2 className="xl white"><span style={{ fontSize: 20 }} className="grey">Explore Our Creative Showcase:</span><br /><AnimatedText line1="Where Vision Comes" line2="to Life" /></h2>
                    </Col>
                    <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} style={{ maxWidth: '100%' }} src="images/PortfolioImage.png" alt="" /></Col>
                </Row>
            </div>
            <img className="HomeBannerBg" src="images/PortfolioBg.png" alt="" />
        </Container>}
        {isHome ? <InnerContent /> : <div className="section-pad">
            <InnerContent />
        </div>}
    </>
    );
}

export default Portfolio