import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import {
    useParams
} from "react-router-dom";
import { ArrowUpRight, PlusLg, DashLg } from 'react-bootstrap-icons';
import { motion } from "framer-motion";
import Accordion from "../Accordion";
import AnimatedText from "../AnimatedText";
import designServices from './designServices.json';
import developmentServices from './developmentServices.json';
import digitalMarketingServices from './digitalMarketingServices.json';
import aiPoweredDesignServices from './aiPoweredDesignServices.json';
import Services from '../Services/services.json'
import './Tools.scss';

const Tools = () => {
    const items: any = Services;
    const [activeService, setActiveService] = useState("Design Services")
    const { service } = useParams();

    const getImageSrc = () => {
        let imageSrc = "images/Design_Logos.png"
        if (activeService === "Development Services") {
            imageSrc = "images/Development_Logos.png"
        } else if (activeService === "Digital Marketing Services") {
            imageSrc = "images/DigitalMar_Logos.png"
        } else if (activeService === "AI-Powered Design Services") {
            imageSrc = "images/AI_Logos.png"
        }
        return imageSrc
    }

    useEffect(() => {
        if (service) {
            setActiveService(service.split('_').join(' '))
        }
    }, [service])
    return <>
        <Container fluid className="HomeBanner">
            <div className="content">
                <Row className="align-center">
                    <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                        <h2 className="xl white mrgLft15Per"><span style={{ fontSize: 20 }} className="grey">Unveiling Our Arsenal:</span><br /><AnimatedText line1="Tools of the Trade" /></h2>
                    </Col>
                    <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} style={{ maxWidth: '100%' }} className="aligh-btm" src="images/headimg_tools.jpg" alt="" /></Col>
                </Row>
            </div>
            <img className="HomeBannerBg mob-btm-25" src="images/Pattern_Right-tools.png" alt="" />
            <img className="HomeBannerBg top left mob-lt-150" src="images/Pattern_Left-tools.png" alt="" />
        </Container>
        <div className="section-pad">
            <Container fluid>
                <motion.h4 initial={{ borderWidth: 0 }}
                    whileInView={{
                        borderWidth: 5, transition: {
                            delay: 0.5, duration: 1
                        }
                    }}>OUR<br /> TOOLSET</motion.h4>
                <p className="main-header-text">Tools aren't just instruments; they're extensions of our creativity, pushing boundaries and
                    streamlining workflows.</p>
                <br />
            </Container>
            <Container fluid>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <p>TOOLS <ArrowUpRight size={20} className="left-align-icon" /></p>
                    </div>
                </div>
                <Row>
                    <Col sm={12} md={12} lg={6}>
                        <Row>
                            <Col style={{ padding: 0 }} sm={12} md={12} lg={11}>
                                {Object.keys(items).map((item, index) => <Fragment key={index}><div id={item.toLowerCase().split(' ').join('-')} onClick={() => {
                                    if (activeService === item) {
                                        setActiveService("")
                                    } else {
                                        setActiveService(item)
                                    }
                                }} className={activeService === item ? 'sector-head' : 'other-services'}>{item.toUpperCase()}
                                    {activeService === item ? <DashLg size={20} className="icon-align-right" /> : <PlusLg size={20} className="icon-align-right" />}
                                </div>
                                    <div className="design-services" style={{ marginBottom: 5 }}>
                                        {activeService === item && <p>{items[activeService]['altDescription']}</p>}
                                        {activeService === item && activeService === 'Design Services' && <Accordion content={designServices} />}
                                        {activeService === item && activeService === 'Design Services' && <Col sm={12} md={12} lg={6} className="mobileToolsDetail" style={{ textAlign: 'center', background: '#fff' }}>

                                            <motion.img initial={{ scale: 0.5 }} whileInView={{
                                                scale: 0.75, transition: {
                                                    delay: 0.5, duration: 1
                                                },
                                            }} style={{ maxWidth: '100%', width: '80%', textAlign: 'center' }} src={getImageSrc()} alt="" />

                                        </Col>}
                                        {activeService === item && activeService === 'Development Services' && <Accordion content={developmentServices} />}
                                        {activeService === item && activeService === 'Development Services' && <Col sm={12} md={12} lg={6} className="mobileToolsDetail" style={{ textAlign: 'center', background: '#fff' }}>

                                            <motion.img initial={{ scale: 0.5 }} whileInView={{
                                                scale: 0.75, transition: {
                                                    delay: 0.5, duration: 1
                                                },
                                            }} style={{ maxWidth: '100%', width: '80%', textAlign: 'center' }} src={getImageSrc()} alt="" />

                                        </Col>}
                                        {activeService === item && activeService === 'Digital Marketing Services' && <><Accordion content={digitalMarketingServices} />
                                        {activeService === item && activeService === 'Digital Marketing Services' && <Col sm={12} md={12} lg={6} className="mobileToolsDetail" style={{ textAlign: 'center', background: '#fff' }}>

                                            <motion.img initial={{ scale: 0.5 }} whileInView={{
                                                scale: 0.75, transition: {
                                                    delay: 0.5, duration: 1
                                                },
                                            }} style={{ maxWidth: '100%', width: '80%', textAlign: 'center' }} src={getImageSrc()} alt="" />

                                        </Col>}
                                            {/* <p>These tools and technologies collectively form the backbone of our web development and our designers and UX professionals to create visually stunning designs, endeavors, allowing us to craft solutions that seamlessly blend innovation, functionality, and scalability. In our digital agency, each project is a canvas where these tools collaborate harmoniously to shape the digital experiences of tomorrow.</p> */}
                                        </>}
                                        {activeService === item && activeService === 'AI-Powered Design Services' && <><Accordion content={aiPoweredDesignServices} />
                                        {activeService === item && activeService === 'AI-Powered Design Services' && <Col sm={12} md={12} lg={6} className="mobileToolsDetail" style={{ textAlign: 'center', background: '#fff' }}>

                                            <motion.img initial={{ scale: 0.5 }} whileInView={{
                                                scale: 0.75, transition: {
                                                    delay: 0.5, duration: 1
                                                },
                                            }} style={{ maxWidth: '100%', width: '80%', textAlign: 'center' }} src={getImageSrc()} alt="" />

                                        </Col>}
                                            {/* <p>In our digital agency, these AI-based design tools are catalysts for innovation, extending the realm of possibilities. They seamlessly integrate with our creative process, ensuring that every design we craft is not just visually compelling but also infused with the power of artificial intelligence. Welcome to a space where human ingenuity collaborates harmoniously with cutting-edge AI tools, defining the essence of our digital agency at the forefront of design innovation.</p> */}
                                        </>}
                                        {/* <p>"In our dynamic agency, we use cutting-edge tools like WordPress, React, Figma, and SEMrush to shape web design, UI/UX, and digital marketing strategies. Welcome to a space where innovation meets human ingenuity."</p> */}
                                    </div>
                                </Fragment>)}
                                <br className="hideOnMobile"/>
                                <br className="hideOnMobile"/>
                                <br className="hideOnMobile"/>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12} lg={6}  className="defaultToolsDetail" style={{ textAlign: 'center' }}>

                        <motion.img initial={{ scale: 0.5 }} whileInView={{
                            scale: 0.75, transition: {
                                delay: 0.5, duration: 1
                            },
                        }} style={{ maxWidth: '100%', width: '80%', textAlign: 'center' }} src={getImageSrc()} alt="" />

                    </Col>
                </Row>
            </Container></div>

    </>
}

export default Tools