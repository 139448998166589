import React from "react"
import ScrollToView from '../ScrollToView'
import Header from '../Header'
import Footer from '../Footer'
import './index.scss';

const Layout = ({ children }: any) => {
  return <React.Fragment>
    <ScrollToView />
    <Header />
    <div className="container-pad" >
      {children}
    </div>
    <Footer />
  </React.Fragment>
}

export default Layout