import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from "framer-motion";
import Accordion from "../Accordion";
import AnimatedText from "../AnimatedText";
import technology from './technology.json';
import './style.scss';

const Technology = () => {
    return (<>
        <Container fluid className="HomeBanner">
            <div className="content">
                <Row className="align-center">
                    <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                        <h2 className="xl white"><span style={{ fontSize: 20 }} className="grey">Empowering Innovation:</span><br /><AnimatedText line1="Where Technology" line2="Fuels Digital Excellence" /></h2>
                    </Col>
                    <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} style={{ maxWidth: '100%' }} className="aligh-btm" src="images/TechnologyImage.png" alt="" /></Col>
                </Row>
            </div>
            <img className="HomeBannerBg mob-rt-40-per" src="images/TechnologyBg.png" alt="" />
        </Container>
        <div className="section-pad">
            <Container fluid>
                <motion.h4 initial={{ borderWidth: 0 }}
                    whileInView={{
                        borderWidth: 5, transition: {
                            delay: 0.5, duration: 1
                        }
                    }}>OUR<br /> TECHNOLOGY</motion.h4>
                <p>In our digital agency, technology fuels innovation. We leverage cutting-edge tools for immersive user experiences and seamless web development, ensuring our clients lead with AI-driven design and responsive interfaces. Empowering businesses with transformative solutions, we drive progress in the digital era.</p>
                <br />
                <br />
                <br />
            </Container>
            <Container fluid>
                <Row>
                    <Col sm={12} md={12} lg={6}>
                        <Row>
                            <Col sm={12} md={12} lg={11}>
                                <div className="sector-head">Various Technologies that we use
                                    <br />
                                    <div className="subText">At Digitellect, we leverage a spectrum of technologies including
                                        graphic design software, web development tools, mobile app frameworks, and collaboration platforms to craft compelling digital
                                        experiences.</div>
                                </div>
                                <Accordion content={technology} subText="In our digital agency, in today's business world. From immersive user experiences to seamless web development, we leverage cutting-edge technology to elevate brands in the digital landscape. With a commitment to staying ahead of advancements, we ensure our clients lead the way, integrating AI-driven design, responsive interfaces, and seamless integrations. Our agency serves as a beacon of innovation, empowering businesses with transformative solutions that transcend expectations, driving progress in an ever-evolving digital era." />
                                <br />
                                <br />
                                <br />
                            </Col></Row></Col>
                    <Col sm={12} md={12} lg={6}>
                        <h3 id="accordion-right-side-header" className="accordion-right-side-header align-ctr">A digital design agency</h3>
                        <motion.img initial={{ scale: 0.8 }} whileInView={{
                            scale: 1, transition: {
                                delay: 0.5, duration: 1
                            },
                        }} style={{ maxWidth: '100%' }} src="images/Technology-2.jpg" alt="" />
                    </Col>
                </Row>
            </Container></div>
    </>
    );
}

export default Technology