import React from "react"
import { Container, Form, Row, Col } from 'react-bootstrap';

const values = [
    "ASAP",
    "In the next week",
    "In the next 1 - 2 weeks",
    "In the next month",
    "In the next 1 - 2 months"
]

const When = ({ register, errors, getValues, show }: any) => {
    return show && <Container fluid style={{ paddingTop: 50, display: show ? 'block' : 'none' }}>
        <div className="box-wrap">
            <Form.Group className="mb-3" controlId="when">
                <Row>
                    {[...values, 'others'].map((value: any, index: any) => <Col sm={12} md={12} lg={12} key={index}><Form.Check
                        type="radio"
                        aria-describedby="Services"
                        id={value}
                        value={value}
                        {...register("when", {
                            required: "Please select the timeline"
                        })}
                        aria-invalid={errors.when ? "true" : "false"}
                        label={value === 'others' ? <Form.Control type="text" placeholder="Others" {...register("whenOther", {
                            validate: (value: any) => {
                                if (!value && getValues('when') === 'others') return 'Please enter other value'
                                return true
                            }
                        })} /> : value}
                    /></Col>)}

                    {errors.when && <p role="alert">{`${errors.when.message}`}</p>}
                    {errors.whenOther && <p role="alert">{`${errors.whenOther.message}`}</p>}
                </Row>
            </Form.Group>
        </div>
    </Container>
}

export default When