import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './style.scss';

const _Accordion = ({ content, subText = "" }: any) => <div className='accordion-wrapper'><Accordion flush defaultActiveKey="0">
    {content.map((item: any, index: any) => <Accordion.Item key={`${index}`} eventKey={`${index}`}>
        <Accordion.Header>{item.title}</Accordion.Header>
        <Accordion.Body>
            {item.description}
        </Accordion.Body>
    </Accordion.Item>)}
    {subText && <p className='subText'>{subText}</p>}
</Accordion></div>

export default _Accordion