import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from "framer-motion";
import AnimatedText from "../AnimatedText";
import './style.scss';

const Team = () => {
    return (<>
        <Container fluid className="HomeBanner">
            <div className="content">
                <Row className="align-center">
                    <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                        <h2 className="xl white mrgLft15Per"><span style={{ fontSize: 20 }} className="grey">Catalysts for Digital Excellence</span><br/><AnimatedText line1="Meet the Minds" line2="Behind the Magic" /></h2>
                    </Col>
                    <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} style={{ maxWidth: '100%' }} className="aligh-btm" src="images/headimg_Team.png" alt="" /></Col>
                </Row>
            </div>
            <img className="HomeBannerBg" src="images/Pattern_Right-team.png" alt="" />
            <img className="HomeBannerBg top left mob-lt-150" src="images/Pattern_Left-Team.png" alt="" />
        </Container>
        <div className="section-pad">
        <Container fluid>
            <div className="mb-50">
                <motion.h4 initial={{ borderWidth: 0 }}
                whileInView={{
                    borderWidth: 5, transition: {
                        delay: 0.5, duration: 1
                    }
                }}>OUR<br /> TEAM</motion.h4>
                <p className="main-header-text">At Digitellect, our diverse team is the heartbeat of our creativity. From visionary creatives to tech-savvy developers, each member contributes unique skills. We thrive on collaboration, driving projects forward with open communication and collective problem-solving. Together, we deliver innovative designs that exceed expectations.</p>
                {/* <p>"Meet the creative minds behind Digitellect. Our diverse team of designers brings together a wealth of experience and a shared passion for pushing creative boundaries. From graphic/ visual design wizards to UX/UI maestros, and UI developers, each of our team contributes a unique skill set that fuels our commitment to delivering top-notch digital design solutions."</p>
                <p>At "Digitellect", our team is the heartbeat of our creative engine. Comprising diverse talents and perspectives, we unite under a shared passion for pushing the boundaries of digital design. Each member brings a unique skill set, from visionary creatives to tech-savvy developers, collaborating seamlessly to deliver innovative solutions.</p>
                <p>Our team thrives on a culture of collaboration, where open communication and collective problem-solving drive our projects forward. We believe in the power of synergy, where the intersection of expertise leads to designs that not only meet but exceed client expectations.</p> */}
            </div>
            <Row>

                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/Team.jpg" alt="" className="work-items" />
                    <p className="sub-head align-ctr">Creative Director/ UX Strategist<br/>Shaikes John</p>

                </Col>
                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/Team.jpg" alt="" className="work-items" />
                    <p className="sub-head align-ctr">Sr. Web Developer<br/>Sharmila B</p>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/Team.jpg" alt="" className="work-items" />
                    <p className="sub-head align-ctr">Director- Business Development<br/>Manju Nair</p>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                    scale: 1, transition: {
                    delay: 0.5, duration: 1
                },
                }} src="images/Team.jpg" alt="" className="work-items" />
                    <p className="sub-head align-ctr">Visual Designer<br/>Vasudhevan</p>
                </Col>
            </Row>

        </Container></div>
    </>

    );
}

export default Team