import React, { useEffect, useState } from "react"
import { Container, Form, Row, Col } from 'react-bootstrap';

const Contact = ({ register, errors, show, Country, setValue, getValues }: any) => {
    const [countries, setCountries] = useState([])
    useEffect(() => {
        const _countries: any = Country.getAllCountries()
        setCountries(_countries)
        if (!getValues('countryCode')) {
            setValue('countryCode', '91')
        }
    }, [Country, setValue, getValues])
    return show && <Container fluid style={{ paddingTop: 50, display: show ? 'block' : 'none' }}>
        <div className="box-wrap">
            <Row className="mb-5">
                <Col sm={12} md={12} lg={12}>
                    <Form.Control
                        type="text"
                        id="name"
                        placeholder="Name*"
                        aria-describedby="Name"
                        {...register("name", {
                            required: "Please enter name"
                        })}
                        aria-invalid={errors.name ? "true" : "false"}
                    />
                    {errors.name && <p role="alert">{`${errors.name.message}`}</p>}
                </Col>
                <Col sm={12} md={12} lg={12}>
                    <Form.Control
                        type="email"
                        id="email"
                        placeholder="Email*"
                        aria-describedby="Email"
                        {...register("email", {
                            required: "Please enter email address", validate: {
                                matchPattern: (v: any) =>
                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                    "Please enter valid email address",
                            }
                        })}
                        aria-invalid={errors.email ? "true" : "false"}
                    />
                    {errors.email && <p role="alert">{`${errors.email.message}`}</p>}
                </Col>
                <Col sm={12} md={12} lg={12}>
                    <Row>
                        <Col lg={5} sm={5} md={5} className="mrgBtm0">
                            <Form.Select id="countryCode"
                                aria-describedby="Country Code" aria-invalid={errors.countryCode ? "true" : "false"} {...register("countryCode", {
                                    required: "Please select country code"
                                })}>
                                {countries.filter((item: any) => item.phonecode).map((item: any, index: any) => <option key={index} value={item.phonecode}>{item.name} ({item.phonecode})</option>)}
                            </Form.Select>
                        </Col>
                        <Col lg={7} sm={7} md={7} className="mrgBtm0">
                            <Form.Control
                                type="text"
                                id="contact"
                                placeholder="Contact Number*"
                                aria-describedby="Contact Number"
                                {...register("number", {
                                    required: "Please enter contact number", validate: {
                                        matchPattern: (v: any) =>
                                            /^[+]?[(]?[0-9]{0,9}[)]?[-\s.]?[0-9]{0,9}[-\s.]?[0-9]{1,15}$/.test(v) ||
                                            "Please enter valid contact number",
                                    }
                                })}
                                aria-invalid={errors.number ? "true" : "false"}
                            />
                            {/* {!errors.number && <p role="alert">Please enter with country code (eg: +91 9980838226)</p>} */}
                            {errors.number && <p role="alert">{`${errors.number.message}`}</p>}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={12} lg={12}>
                    <Form.Control
                        type="text"
                        id="organization"
                        placeholder="Organization"
                        aria-describedby="Organization"
                        {...register("quoteOrganization")}
                    />
                </Col>
                <Col sm={12} md={12} lg={12}>
                    <Form.Control as="textarea" aria-label="Message" placeholder="Message" aria-describedby="Message" rows={3} {...register("message")} />
                </Col>
            </Row>
        </div>
    </Container>
}

export default Contact