import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import {
    Link, useParams
} from "react-router-dom";
import { ArrowUpRight, PlusLg, DashLg, XLg } from 'react-bootstrap-icons';
import { motion } from "framer-motion";

import AnimatedText from "../AnimatedText";
import Quote from '../Quote'
import Button from '../Button';
import Box from './Box'
import HomeServices from './homeServices.json'
import Services from './services.json'

const Service = ({ isHome = false }: any) => {
    const items: any = Services;
    const homeItems: any = HomeServices;
    const [activeService, setActiveService] = useState("")
    // const [activeSubService, setActiveSubService] = useState("")
    const [showQuote, setShowQuote] = useState(false);
    const { service, subservice } = useParams();
    useEffect(() => {
        if (service) {
            setActiveService(service.split('_').join(' '))
            // setActiveSubService(subservice ? subservice.split('_').join(' ') : "")
        }
    }, [service, subservice])
    return <>
        {isHome ?
            <Row>
                <Col>
                    <Row>
                        <div>
                            <motion.h4 initial={{ borderWidth: 0 }}
                                whileInView={{
                                    borderWidth: 5, transition: {
                                        delay: 0.5, duration: 1
                                    }
                                }}>WHAT<br></br> WE DO</motion.h4>
                            <div className="link-text text-end all-services mb-10">
                                <Link to="/services">
                                    <ArrowUpRight size={20} className="left-align-icon" /> ALL SERVICES
                                </Link>
                            </div>
                        </div>
                        {Object.keys(homeItems).map((item, index) => <Col key={index} sm={12} md={12} lg={6}>
                            <Box
                                title={item}
                                text={homeItems[item]['description']}
                                icon={homeItems[item]['icon']}
                                desc={<Row><Col className="more-details">
                                    {Object.keys(homeItems[item]['services']).slice(0, 2).map((_item, index) => <Link key={index} to={`/services/${item.split(' ').join('_')}/${_item.split(' ').join('_')}#serviceDetail`}><Button key={index} variant="primary">{_item}</Button></Link>)}<br />
                                    {Object.keys(homeItems[item]['services']).slice(2, 4).map((_item, index) => <Link key={index} to={`/services/${item.split(' ').join('_')}/${_item.split(' ').join('_')}#serviceDetail`}><Button key={index} variant="primary">{_item}</Button></Link>)}
                                </Col>
                                </Row>}
                            />
                        </Col>)}
                    </Row>
                </Col>
            </Row>
            : <><Container fluid className="HomeBanner">
                <div className="content">
                    <Row className="align-center">
                        <Col sm={12} md={6} lg={{ span: 7 }} className="caption">
                            <h2 className="xl white mrgTop10Per"><span style={{ fontSize: 20 }} className="grey">Embarking on Uncharted Frontiers:</span><br /> <AnimatedText line1="Nurturing Innovation with Intuitive Imagination" /></h2>

                        </Col>
                        <Col sm={12} md={6} lg={5}><motion.img initial={{ scale: 0.8 }} whileInView={{
                            scale: 1, transition: {
                                delay: 0.5, duration: 1
                            },
                        }} style={{ maxWidth: '100%' }} className="aligh-btm" src="images/ServicesImage.png" alt="" /></Col>
                    </Row>
                </div>
                <img className="HomeBannerBg top" src="images/ServicesBg.png" alt="" />
            </Container>
                <div className="section-pad">
                    <Container fluid>
                        <motion.h4 initial={{ borderWidth: 0 }}
                            whileInView={{
                                borderWidth: 5, transition: {
                                    delay: 0.5, duration: 1
                                }
                            }}>OUR<br></br> SERVICES</motion.h4>
                        <p className="main-header-text">Elevate your brand in the digital space with our agency's expert services. From sleek web design to targeted digital marketing, we craft tailored solutions that leave a lasting impact. Join us to redefine your digital journey</p>
                        <div className="row more-pad">
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-20 mt-20">
                                <span className="all-services">ALL SERVICES <ArrowUpRight size={20} className="left-align-icon" /></span>
                            </div>
                        </div>

                        <Quote show={showQuote} onHide={() => setShowQuote(false)} />
                        <Row className="more-pad">
                            <Col sm={12} md={12} lg={4}>
                                <Row>
                                    <Col style={{ padding: 0 }} sm={12} md={12} lg={11}>
                                        {Object.keys(items).map((item, index) => <Fragment key={index}><div id={item.toLowerCase().split(' ').join('-')} onClick={() => {
                                            if (activeService === item) {
                                                setActiveService("")
                                            } else {
                                                setActiveService(item)
                                            }
                                            // setActiveSubService("")
                                        }} className={activeService === item ? 'sector-head' : 'other-services'}>{item.toUpperCase()}
                                            {activeService === item ? <DashLg size={20} className="icon-align-right" /> : <PlusLg size={20} className="icon-align-right" />}
                                        </div>
                                            <div className="more-services" style={{ marginBottom: 5 }}>
                                                {activeService === item && <div className="description">{items[activeService]['altDescription']}</div>}
                                                {/* {activeService === item && Object.keys(items[activeService]['services']).map((_item, _index): any => <p className={activeSubService === _item ? 'active' : ''} onClick={() => onLeftNavClick(_item)} key={_index}>{_item}<ArrowRight size={20} className="right-align-icon" /></p>)} */}
                                            </div>

                                            {activeService === item && <Col id="serviceDetail" className="mb-10 mobileServiceDetail" sm={12} md={12} lg={8}>
                                                <XLg size={20} onClick={() => setActiveService("")} className="right-align-icon" /><Row>
                                                    <Col sm={12} md={12} lg={12}><h2 id="accordion-right-side-header" className="accordion-right-side-header">{activeService.toUpperCase()}</h2></Col>
                                                    {/* {items[activeService]['services'][activeSubService].length > 1 ? items[activeService]['services'][activeSubService].map((_item = "", _index = 0) => <Col key={_index} sm={12} md={6} lg={6}><p className="accordion-right-side-text">{_item}</p></Col>) : <Col sm={12} md={12} lg={12}><p className="accordion-right-side-text">{items[activeService]['services'][activeSubService][0]}</p></Col>} */}

                                                    {Object.keys(items[activeService]['services']).map((_item = "", _index = 0) => <Col key={_index} sm={12} md={6} lg={6}><p className="accordion-right-side-text">{_item}</p></Col>)}
                                                </Row>
                                            </Col>}
                                        </Fragment>)}
                                    </Col>
                                </Row>
                            </Col>

                            <Col id="serviceDetail" className="mb-10 defaultServiceDetail" sm={12} md={12} lg={8}>

                                {activeService ? <><XLg size={20} onClick={() => setActiveService("")} className="right-align-icon" /><Row>
                                    <Col sm={12} md={12} lg={12}><h2 id="accordion-right-side-header" className="accordion-right-side-header">{activeService.toUpperCase()}</h2></Col>
                                    {/* {items[activeService]['services'][activeSubService].length > 1 ? items[activeService]['services'][activeSubService].map((_item = "", _index = 0) => <Col key={_index} sm={12} md={6} lg={6}><p className="accordion-right-side-text">{_item}</p></Col>) : <Col sm={12} md={12} lg={12}><p className="accordion-right-side-text">{items[activeService]['services'][activeSubService][0]}</p></Col>} */}

                                    {Object.keys(items[activeService]['services']).map((_item = "", _index = 0) => <Col key={_index} sm={12} md={6} lg={6}><p className="accordion-right-side-text">{_item}</p></Col>)}
                                </Row></>
                                    : <Row>
                                        <p>In the dynamic digital landscape, our agency excels in various services to enhance your online presence. From web design to digital marketing, we craft tailored solutions with creativity and technical expertise. Partner with us to redefine your digital journey.</p>
                                    </Row>}
                            </Col>

                        </Row>
                    </Container>
                </div>
            </>}
    </>
}

export default Service