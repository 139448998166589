const emailUrl = '/send-email/trigger.php'
const countryUrl = 'https://api.countrystatecity.in/v1/countries'

const get = (url: any, options: any) => fetch(url, options)
    .then((res) => res.json())
const post = (url: any, data: any) => fetch(url, {
    method: 'POST',
    body: data
})
    .then((res) => res.json())

var countryAPIheaders = new Headers();
countryAPIheaders.append("X-CSCAPI-KEY", "API_KEY");

var countryAPIOptions = {
    method: 'GET',
    headers: countryAPIheaders,
    redirect: 'follow'
};

export const getCountries = () => get(countryUrl, countryAPIOptions)
export const getStates = (countryCode: any) => get(`${countryUrl}/${countryCode}/states`, countryAPIOptions)
export const getCities = (countryCode: any, stateCode: any) => get(`${countryUrl}/${countryCode}/states/${stateCode}/cities`, countryAPIOptions)

const trigger = (data: any) => post(emailUrl, data)

export const newsletter = (email: any) => {
    const data = new FormData()
    data.append('type', 'newsletter')
    data.append('email', email)
    return trigger(data)
}

export const contact = ({ name, email, number, organization, message }: any) => {
    const data = new FormData()
    data.append('type', 'contactus')
    data.append('name', name)
    data.append('email', email)
    data.append('number', number)
    data.append('organization', organization)
    data.append('message', message)
    return trigger(data)
}

export const quote = ({ name, email, number, organization, message, who, country, state, city, service, kind, when }: any) => {
    const data = new FormData()
    data.append('type', 'quote')
    data.append('name', name)
    data.append('email', email)
    data.append('number', number)
    data.append('organization', organization)
    data.append('message', message)
    data.append('who', who)
    data.append('country', country)
    data.append('state', state)
    data.append('city', city)
    data.append('service', service)
    data.append('kind', kind)
    data.append('when', when)
    return trigger(data)
}