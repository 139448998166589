import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from "framer-motion";
import './style.scss';

const Clients = () => {
    return (

        <Container fluid style={{ paddingTop: 35 }} className="our-clients">
            <div>
                <motion.h4 initial={{ borderWidth: 0 }}
                    whileInView={{
                        borderWidth: 5, transition: {
                            delay: 0.5, duration: 1
                        }
                    }}>OUR <br></br>CLIENTS</motion.h4>
                <p className="main-header-text">In our digital design agency, clients are the heartbeat of our creative endeavors. Each project is a shared journey, a testament to the unique stories we craft together in digital innovation.</p>
            </div>
            <Row>

                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/client1.jpg" alt="" className="client-work" />
                    <p className="sub-head">Branding and identity design</p>
                    <p>Branding and identity design is a comprehensive process that involves creating a visual and conceptual representation of a brand. It encompasses various elements that collectively contribute to how a brand is perceived by its audience.</p>

                </Col>
                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/client2.jpg" alt="" className="client-work" />
                    <p className="sub-head">Maximize your social media strategy</p>
                    <p>Social media graphics encompass a wide range of visual elements created specifically for sharing on various social media platforms. These graphics are designed to capture attention, convey messages, and engage the audience.</p>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/work3.jpg" alt="" className="client-work" />
                    <p className="sub-head">Mobile App Design Services</p>
                    <p>Mobile app design encompasses various elements and considerations to create a user-friendly and visually appealing experience tailored specifically for mobile devices. Here are key aspects that fall under mobile app design:</p>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} src="images/work4.jpg" alt="" className="client-work" />
                    <p className="sub-head">Visual Storytelling through Interactive Presentation Elements</p>
                    <p>Emphasize your ability to visually tell a story through presentations. Showcase designs that effectively communicate complex ideas or narratives.</p>
                </Col>
            </Row>

        </Container>


    );
}

export default Clients