import React from "react"
import { Container, Row, Col, Form } from 'react-bootstrap';

const WhatKind = ({ register, errors, kinds, show, getValues }: any) => {
    return show && <Container fluid style={{ paddingTop: 50, display: show ? 'block' : 'none' }}>
        <div className="box-wrap">
            <Form.Group className="mb-3" controlId="servicesKind">
                <Row>
                    {[...kinds, 'others'].map((kind: any, index: any) => <Col sm={12} md={6} lg={kind === 'others' ? 12 : 6} key={index}><Form.Check
                        type="checkbox"
                        value={kind}
                        aria-describedby="Services"
                        id={kind}
                        {...register("kind", {
                            required: "Please select services"
                        })}
                        aria-invalid={errors.kind ? "true" : "false"}
                        label={kind === 'others' ? <Form.Control type="text" placeholder="Others" {...register("kindOther", {
                            validate: (value: any) => {
                                if (!value && getValues('kind').includes('others')) return 'Please enter other value'
                                return true
                            }
                        })} /> : kind}
                    /></Col>)}
                    {errors.kind && <p role="alert">{`${errors.kind.message}`}</p>}
                    {errors.kindOther && <p role="alert">{`${errors.kindOther.message}`}</p>}
                </Row>
            </Form.Group>
        </div>
    </Container>
}

export default WhatKind