import React from "react"
import { Row, Col, Container } from 'react-bootstrap';
import { motion } from "framer-motion";

import AboutUs from '../AboutUs'
import Services from '../Services'
import Portfolio from '../Portfolio'
import Newsletter from '../Newsletter'
import Clients from '../Clients'
import AnimatedText from "../AnimatedText";
import '../index.css';
import './style.scss';

function Home() {
    return (<div className="HomeContainer">
        <Container fluid className="HomeBanner">
            <div className="content">
                <Row className="align-center">
                    <Col sm={12} md={6} lg={{ span: 6 }} className="caption">
                        <h2 className="xl white mrgTop20Per"><span style={{ fontSize: 20 }} className="grey">Unlocking Innovation:</span><br /><AnimatedText line1="Harmonizing the essence of" line2="‘Digital & Intellect’" /></h2>
                    </Col>
                    <Col sm={12} md={6} lg={6}><motion.img initial={{ scale: 0.8 }} whileInView={{
                        scale: 1, transition: {
                            delay: 0.5, duration: 1
                        },
                    }} style={{ maxWidth: '100%' }} className="home-main" src="images/HomeImage.png" alt="" /></Col>
                </Row>
            </div>
            {/* <img className="HomeBannerBg top fit" src="images/HomeBg.png" alt="" /> */}
            <img className="HomeBannerBg top right mob-rt-25-per tab-rt-5-per" src="images/HomePattern-right.png" alt="" />
            <img className="HomeBannerBg top left mob-lt-60-per tab-lt-48-per" src="images/HomePattern-left.png" alt="" />
        </Container>
        <div className="section-pad">
            <AboutUs isHome={true} />
            <Services isHome={true} />
            <Portfolio isHome={true} />
            <Container fluid style={{ marginTop: 20 }}>
                <Newsletter />
            </Container>
            <Clients />
        </div>
    </div>
    );
}

export default Home