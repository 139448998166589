import React from "react"
import { Container, Tabs, Tab, Row, Col } from 'react-bootstrap';
import Privacy from './Privacy';
import Terms from './Terms';
import RefundCancellation from './RefundCancellation';
import './style.scss';

const Policy = () => {
    return <Container fluid className="footerbox-pad">
        <Row>
            <Col sm={12}>
                <div className="row getintouchu-panel">
                    <div className="col-lg-9 col-md-6 col-sm-12">
                        <h2>PRIVACY POLICY</h2>
                    </div>
                </div>
            </Col>
        </Row>


        <Row className="form-bg">
            <Col sm={12}>
                <Tabs
                    defaultActiveKey="privacy"
                    id="uncontrolled-tab-example"
                     fill
                >
                    <Tab eventKey="privacy" title="Privacy Statement">
                        <Privacy />
                    </Tab>
                    <Tab eventKey="terms" title="Terms of Use">
                        <Terms />
                    </Tab>
                    <Tab eventKey="refundCancellation" title="Refund & Cancellation Policy">
                        <RefundCancellation />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
}

export default Policy