import React from "react"
import { Container, Form, Row, Col } from 'react-bootstrap';

const values = [
    "Individual",
    "Business",
    "Educational institution",
    "Non-profit organization",
]

const Who = ({ register, errors, getValues, show }: any) => {
    return show && <Container fluid style={{ paddingTop: 50, display: show ? 'block' : 'none' }}>
        <div className="box-wrap">
            <Form.Group className="mb-3" controlId="who">
                <Row>
                    {[...values, 'others'].map((value: any, index: any) => <Col sm={12} md={12} lg={12} key={index} ><Form.Check
                        type="radio"
                        aria-describedby="Who"
                        value={value}
                        id={value}
                        {...register("who", {
                            required: "Please select the timeline"
                        })}
                        aria-invalid={errors.who ? "true" : "false"}
                        label={value === 'others' ? <Form.Control type="text" placeholder="Others" {...register("whoOther", {
                            validate: (value: any) => {
                                if (!value && getValues('who') === 'others') return 'Please enter other value'
                                return true
                            }
                        })} /> : value}
                    /> </Col>)}
                    {errors.who && <p role="alert">{`${errors.who.message}`}</p>}
                    {errors.whoOther && <p role="alert">{`${errors.whoOther.message}`}</p>}
                </Row>
            </Form.Group>
        </div>
    </Container>
}

export default Who